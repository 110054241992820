import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const ImageGrid = ({
	heading,
	content,
	galleryItems,
	btnCta,
	bgImage,
	bgColor,
	textColor = "#fff",
}) => {
	return (
		<section className="py-5 py-md-8" style={sectionStyle(bgImage, bgColor)}>
			<Container>
				{heading && (
					<Row className="align-items-lg-center justify-content-lg-center">
						<Col lg={8} className="mb-4 mb-lg-0 text-center">
							<h2 className="my-0" style={{ color: textColor }}>
								{heading}
							</h2>
							<div
								className="py-4"
								style={{ color: textColor }}
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
						</Col>
					</Row>
				)}

				{galleryItems && galleryItems.length > 0 && (
					<div className="d-grid gallery-wrapper gap-3 gap-md-4 mt-5">
						{galleryItems.map((item, i) => (
							<div className="gallery-item">
								<GatsbyImage
									key={i}
									image={item?.localFile.childImageSharp.gatsbyImageData}
									alt={item?.altText}
									className="border border-2 border-white rounded-5"
									style={{ height: "100%", width: "100%" }}
								/>
							</div>
						))}
					</div>
				)}
				{btnCta?.url.length > 0 && (
					<div className="d-flex justify-content-center mt-5">
						<Button
							as={Link}
							href={btnCta.url}
							size="lg"
							className="px-4 py-3 montserrat-bold fs-6 text-light-black"
							target={btnCta.target}
							variant="secondary"
						>
							{btnCta.title}
						</Button>
					</div>
				)}
			</Container>
		</section>
	);
};

export const sectionStyle = (bgImage, bgColor) => {
	return {
		background: `linear-gradient(0deg, ${bgColor} 0%, ${bgColor} 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
	};
};

export default ImageGrid;
