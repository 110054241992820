import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import ClientTestimonials from "../components/client-testimonials";
import EventPricing from "../components/event-pricing";
import HeroBanner from "../components/hero";
import ImageGrid from "../components/image-grid";
import Layout from "../components/layout";
import SimpleCta from "../components/simple-cta";
import TwoColumnImageContent from "../components/two-column-image-content";

const CarbonNeutralEvents = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "carbon-conscious-events" }) {
				carbonNeutralEvents {
					carbonBannerSection {
						bannerSectionHeading
						bannerSectionContent
						bannerSectionBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
					carbonTwoColumnImageContent {
						twoColumnHeading
						twoColumnTagline
						twoColumnContent
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
						twoColumnImageBackgroundColor
					}
					carbonTwoColumnBackgroundImage {
						twoColumnHeading
						twoColumnContent
						twoColumnSubHeading
						backgroundColor
						twoColumnBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnTopImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					carbonTwoColumnBottomImage {
						pricingSection {
							pricingCard1 {
								buttonText
								heading
								price
								subtext
								features {
									feature
								}
							}
							pricingCard2 {
								buttonText
								features {
									feature
								}
								heading
								price
								subtext
							}
							pricingCard3 {
								buttonText
								features {
									feature
								}
								heading
								price
								subtext
							}
						}
						twoColumnHeading
						twoColumnSubHeading
						twoColumnContent
						twoColumnButton2 {
							target
							title
							url
						}
						twoColumnButton1 {
							target
							title
							url
						}
					}
					carbonClientTestimonials {
						selectClientTestimonials {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewTypes {
										nodes {
											taxonomyName
											termTaxonomyId
										}
									}
									reviewPostFields {
										reviewAuthor
										reviewAuthorDesignation
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: AVIF
															quality: 30
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: AVIF
															quality: 30
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
									}
									terms {
										nodes {
											taxonomyName
											name
										}
									}
								}
							}
						}
					}
					fiveImageGrid {
						fiveImageGridHeading
						fiveImageGridDescription
						backgroundColor
						backgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						fiveImageGridButton {
							target
							title
							url
						}
						fiveImageGridGallery {
							nodes {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					carbonTextButtonCta {
						simpleTextButtonCtaHeading
						simpleTextButtonCtaContent
						ctaButton {
							target
							title
							url
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, carbonNeutralEvents },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Carbon Conscious Events",
				item: {
					url: `${siteUrl}/carbon-conscious-events`,
					id: `${siteUrl}/carbon-conscious-events`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/carbon-neatural-events`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						carbonNeutralEvents?.carbonBannerSection.bannerSectionHeading
					}
					content={
						carbonNeutralEvents?.carbonBannerSection.bannerSectionContent
					}
					bgImage={
						carbonNeutralEvents?.carbonBannerSection.bannerSectionBackground
							?.node?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<TwoColumnImageContent
					heading={
						carbonNeutralEvents?.carbonTwoColumnImageContent.twoColumnHeading
					}
					content={
						carbonNeutralEvents?.carbonTwoColumnImageContent.twoColumnContent
					}
					tagline={
						carbonNeutralEvents?.carbonTwoColumnImageContent.twoColumnTagline
					}
					btnCta1={
						carbonNeutralEvents?.carbonTwoColumnImageContent.twoColumnCta1
					}
					image={
						carbonNeutralEvents?.carbonTwoColumnImageContent.twoColumnImage
							?.node
					}
					bgColor={
						carbonNeutralEvents?.carbonTwoColumnImageContent
							.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<TwoColumnImageContent
					heading={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage.twoColumnHeading
					}
					content={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage.twoColumnContent
					}
					tagline={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage
							.twoColumnSubHeading
					}
					btnCta1={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage.twoColumnCta1
					}
					btnCta2={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage.twoColumnCta2
					}
					image={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage.twoColumnImage
							?.node
					}
					mainBgColor={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage.backgroundColor
					}
					bgImage={
						carbonNeutralEvents.carbonTwoColumnBackgroundImage
							.twoColumnBackgroundImage?.node.sourceUrl
					}
					bodyTextColor={"#fff"}
					textColor={"#fff"}
					flip={true}
					border={false}
					overlayImage={
						carbonNeutralEvents?.carbonTwoColumnBackgroundImage
							.twoColumnTopImage?.node
					}
				/>
				<EventPricing
					heading={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.twoColumnHeading
					}
					content={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.twoColumnContent
					}
					tagline={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.twoColumnSubHeading
					}
					btnCta1={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.twoColumnButton1
					}
					bgColor={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.backgroundColor
					}
					pricingCard1={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.pricingSection
							.pricingCard1
					}
					pricingCard2={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.pricingSection
							.pricingCard2
					}
					pricingCard3={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.pricingSection
							.pricingCard3
					}
					textColor="#252525"
					border="0px"
					overlayImage={
						carbonNeutralEvents?.carbonTwoColumnBottomImage.overlayImage?.node
					}
				/>
				<ClientTestimonials
					testimonials={
						carbonNeutralEvents?.carbonClientTestimonials
							.selectClientTestimonials.nodes
					}
				/>
				<ImageGrid
					heading={carbonNeutralEvents?.fiveImageGrid.fiveImageGridHeading}
					content={carbonNeutralEvents?.fiveImageGrid.fiveImageGridDescription}
					btnCta={carbonNeutralEvents?.fiveImageGrid.fiveImageGridButton}
					bgColor={carbonNeutralEvents?.fiveImageGrid.backgroundColor}
					bgImage={
						carbonNeutralEvents?.fiveImageGrid.backgroundImage?.node.sourceUrl
					}
					galleryItems={
						carbonNeutralEvents?.fiveImageGrid.fiveImageGridGallery?.nodes
					}
				/>
				<SimpleCta
					heading={
						carbonNeutralEvents?.carbonTextButtonCta.simpleTextButtonCtaHeading
					}
					content={
						carbonNeutralEvents?.carbonTextButtonCta.simpleTextButtonCtaContent
					}
					btnCta1={carbonNeutralEvents?.carbonTextButtonCta.ctaButton}
				/>
			</Layout>
		</>
	);
};

export default CarbonNeutralEvents;
